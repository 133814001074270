import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import './CreateDialogPage.css';
import adapter from '../../../adapter';
import Loader from "../loader/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshAccessToken from '../../refreshAccessToken';


const CreateDialogPage = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const accessToken = localStorage.getItem('accessToken');
    const messageType = [
        { value: 'message', label: 'Message' },
        { value: 'file_image', label: 'File Photo' },
        { value: 'file_audio', label: 'File Audio' },
        { value: 'file_video', label: 'File Video' }
    ]
    const locationOptions = [
        { value: 'private', label: 'В личной папке бота' },
        { value: 'public', label: 'В общей папке' },
  
    ]

    const [dialogLang, setDialogLang] = useState([]);
    const [selectedDialogLang, setSelectedDialogLang] = useState([]);
    const navigate = useNavigate();
    const fetchGetDialogLang = async () => {
        try {
            const response = await adapter.get('/get_dialog_lang', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              }
            });
            if (response.data.result === 'success') {
                const dialogLangs = response.data.array;
                setDialogLang(dialogLangs);
                setSelectedDialogLang(dialogLangs[0]);
            }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
              const refreshedToken = await refreshAccessToken(accessToken);
              if (refreshedToken === 'logout') {
                navigate('/', { replace: true });
              }else {
                const response = await adapter.get('/get_dialog_lang', {
                    headers: {
                      Authorization: `Bearer ${accessToken}`,
                    },
                    params: { userId: userData.id }
                  });
                  if (response.data.result === 'success') {
                      const dialogLangs = response.data.array;
                      setDialogLang(dialogLangs);
                      setSelectedDialogLang(dialogLangs[0]);
                  }
              }
            }else if (response.data.result === 'error' && response.data.message === 'No langs found'){
                setDialogLang([]);
            }else {
              console.log(response.data.message);
            }
          } catch (error) {
            console.log(error)
          }
    };
 
    const [botLabels, setBotLabels] = useState([]);
    const [withOutRootBots , setWithOutRootBots] = useState([]);
    const [rootBotLabel, setRootBotLabel] = useState([]);
    const [selectedRootBot, setSelectedRootBot] = useState([]);
 
    const handleGetUnits = async () => {
      try {
        const response = await adapter.get('/get_units', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          params: { userId: userData.id }
        });
        if (response.data.result === 'success') {
          
          const botLabels = response.data.array.map(bot => ({
            value: bot.id,
            label: bot.username,
          }));
          setBotLabels(botLabels);
        }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
          const refreshedToken = await refreshAccessToken(accessToken);
          if (refreshedToken === 'logout') {
            navigate('/', { replace: true });
          }else {
            const response = await adapter.get('/get_units', {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              params: { userId: userData.id }
            });
            if (response.data.result === 'success') {
              const botLabels = response.data.array.map(bot => ({
                value: bot.id,
                label: bot.username,
              }));
              setBotLabels(botLabels);
            } else {
              console.log(response.data.message);
            }
          }
        }else {
          console.log(response.data.message);
        }
      } catch (error) {
        console.log(error)
      }
    };
    

    const uniqueId = () => Math.random().toString(36).substr(2, 9);
    const [forms, setForms] = useState([{ id: uniqueId(), type: 'message', reply: '', unit: '', location:'private',file:'',text:[]  }]);


    const [dialogData, setDialogData] = useState({
        name:'',
        description:'',
        text:[],
        language:'',
        root: '',
        url:'',
        type:'dialog'
    });

    const handleDialogNameChange = (e)=>{
        setDialogData(
            {
                ...dialogData,
                name:e.target.value
            }
        )
    }
    const handleDialogLinkChange = (e)=>{
        setDialogData(
            {
                ...dialogData,
                url:e.target.value
            }
        )
    }
    const handleDialogDescChange = (e)=>{
        setDialogData(
            {
                ...dialogData,
                description:e.target.value
            }
        )
    }

    const handleBotLableChange = (e)=>{
        setSelectedRootBot(e);
        setRootBotLabel(e);
        setDialogData(
            {
                ...dialogData,
                root:e.value
            }
        )
        console.log(botLabels);
        const filteredBotLabels = botLabels.filter(label => label.value !== e.value && label.label !== e.label);
        setWithOutRootBots(filteredBotLabels);
    }

    const handleDialogLangChange = (e)=>{
        setSelectedDialogLang(e);
        setDialogData(
            {
                ...dialogData,
                language:e.value
            }
        )
    }
    const handleUnitChange = (e)=>{
        setSelectedDialogLang(e);
        setDialogData(
            {
                ...dialogData,
                language:e.value
            }
        )
    }
const addForm = (index) => {
  const newForm = { id: uniqueId(), type: 'message', reply: '', unit: '',location:'private', file:'', text:[] };
  const newForms = [...forms];
  newForms.splice(index + 1, 0, newForm);
  setForms(newForms);
};

const deleteForm = (formId) => {
  setForms(forms.filter((form) => form.id !== formId));
};


const updateFormText = (formId, field, newText) => {
    setForms(forms.map(form => (form.id === formId ? { ...form, [field]: newText } : form)));
  };

const updateSelectValue = (formId, field, newValue) => {
    setForms(forms.map((form) => (form.id === formId ? { ...form, [field]: newValue } : form)));
};

const [sendForm, setSendForm] = useState(false);

const gatherFormData = () => {
    setSendForm(true);
    const formData = forms.map((form, index) => {
      const textArray = typeof form.text === 'string' ? form.text.split('\n') : [];
      return { position: index + 1, type: form.type, reply: form.reply, unit: form.unit, location: form.location, file: form.file, text: textArray };
    });
  
    if (formData.some(form => form.text.length === 0)) {
      toast.error('Вы не написали ниодного сообщения!');
      setSendForm(false);
      return;
    }
  
    setDialogData({
      ...dialogData,
      text: JSON.stringify(formData),
      language: selectedDialogLang.value,
      root: selectedRootBot.value
    });
  };


const [loaderText, setLoaderText] = useState('');

useEffect(() => {
    if (sendForm) {
      if (!dialogData.name) {
        toast.error('Заполните обязательное поле: "Название диалога"');
      }else {
        const updatedDialogData = { ...dialogData, userId: userData.id };
        setLoaderText('Добавляем диалог ');

      const createDialog = async () =>{
        try {
          const response = await adapter.post('/create_dialog',updatedDialogData, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          });
    
          if (response.data.result === 'success') {
              setSendForm(false);
              setForms([{ id: uniqueId(), type: 'message', reply: '', unit: '', location: 'private', file: '', text: [] }]);
              setDialogData({
                name: '',
                description: '',
                text: [],
                language: '',
                root: null,
                type:'dialog'
              });
              setSelectedRootBot([]);
              setSelectedDialogLang([]);
              toast.success('Диалог добавлен в базу!');
              setTimeout(() => {
                  setLoaderText('');
              }, 1500);
              
          } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
            const refreshedToken = await refreshAccessToken(accessToken);
            if (refreshedToken === 'logout') {
              navigate('/', { replace: true });
            } else {
              const response =  adapter.post('/create_dialog',updatedDialogData, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                }
              });
      
              if (response.data.result === 'success') {
                  setSendForm(false);
                  setForms([{ id: uniqueId(), type: 'message', reply: '', unit: '', location: 'private', file: '', text: [] }]);
                  setDialogData({
                  name: '',
                  description: '',
                  text: [],
                  language: '',
                  root: null,
                  type:'dialog'
                  });
                  setSelectedRootBot([]);
                  setSelectedDialogLang([]);
                  toast.success('Диалог добавлен в базу!');
                  setTimeout(() => {
                      setLoaderText('');
                  }, 1500);
                  
              } else {
              setLoaderText('');
              console.log(updatedDialogData);
              console.log(response.data.message);
              }
            }
          } else {
            console.log(response.data.message);
            setLoaderText('');
            console.log(updatedDialogData);
            console.log(response.data.message);
          }
        } catch (error) {
          setLoaderText('');
          toast.error('Произошла ошибка, подробности в консоли');
          console.error(error);
          console.log(updatedDialogData);
        }
      }
      createDialog();
      }
    }
    
    setSendForm(false);
  }, [sendForm]);


useEffect(() => {
    handleGetUnits();
    fetchGetDialogLang();
  }, []);


    return ( 
        <>
    {loaderText !== ''? (
      <Loader loaderText={loaderText}/>
    ):(
        <div className='dialog-page-wrapper'>
            <Form className='create-dialog-form p-5'>
                <Container>
                        <Row className='my-2'>
                            <Col md={4}>
                                <Form.Label>Название диалога:</Form.Label>
                                <Form.Control
                                type="text"
                                name="dialogName"
                                placeholder="Введите название"
                                value={dialogData.name}
                                onChange={handleDialogNameChange}
                                />
                            </Col>
                            <Col md={8}>
                                <Form.Label>Описание диалога <small>(необязательно)</small>:</Form.Label>
                                    <Form.Control
                                    type="text"
                                    name="dialogDesc"
                                    placeholder="Введите описание"
                                    value={dialogData.description}
                                    onChange={handleDialogDescChange}
                                    />
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col md={4}>
                                <Form.Label>Ссылка:</Form.Label>
                                <Form.Control
                                type="text"
                                name="url"
                                placeholder="Добавьте ссылку"
                                value={dialogData.url}
                                onChange={handleDialogLinkChange}
                                />
                            </Col>
                           
                            <Col md={4}>
                                <Form.Label>Выбрать ROOT бота:</Form.Label>
                                <Select name='botLabel' options={botLabels} value={selectedRootBot} onChange={handleBotLableChange}
                                 styles={{
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: '#e9ecef',
                                        color:'rgb(32, 34, 37)', 
                                      }),
                                    option: (baseStyles) => ({
                                    ...baseStyles,
                                    cursor:'pointer'
                                    }),
                                  }}/>
                            </Col>
                            <Col md={4}>
                                <Form.Label>Язык диалога:</Form.Label>
                                <Select name='dialogName' options={dialogLang} value={selectedDialogLang} onChange={handleDialogLangChange}
                                 styles={{
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        backgroundColor: '#e9ecef',
                                        color:'rgb(32, 34, 37)', 
                                      }),
                                    option: (baseStyles) => ({
                                    ...baseStyles,
                                    cursor:'pointer'
                                    }),
                                  }}
                                />
                            </Col>
                        </Row>
                        
                        {forms.map((form, index) => (
                            <div key={form.id}>
                                <Row key={index} className='message p-5 my-5'>
                                    <Col md={1}></Col>
                                    <Col md={10}>
                                        <Row className='mb-5'>
                                            <strong>Сообщение #{index + 1}</strong>
                                        </Row>
                                        <Row className='my-3'>
                                            <Col md={2}>
                                                <Form.Label>Тип сообщения:</Form.Label>
                                                <Select
                                                name="type"
                                                options={messageType}
                                                value={form.type ? messageType.find(option => option.value === form.type) : messageType[0]}
                                                onChange={(selectedOption) => {
                                                    updateSelectValue(form.id,'type', selectedOption.value);
                                                }}
                                                styles={{
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        backgroundColor: '#e9ecef',
                                                        color:'rgb(32, 34, 37)', 
                                                      }),
                                                    option: (baseStyles) => ({
                                                    ...baseStyles,
                                                    cursor:'pointer'
                                                    }),
                                                  }}
                                                />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Label>Ответить на:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="reply"
                                                    placeholder="От 1 до 1000"
                                                    value={form.reply}
                                                    onChange={(e) => updateFormText(form.id, 'reply', e.target.value)}
                                                    
                                                    />
                                            </Col>
                                            <Col md={2}>
                                                <Form.Label>Какой бот:</Form.Label>
                                                <Select name='unit' options={withOutRootBots} value={messageType.find(option => option.value === form.unit) } onChange={(selectedOption) => {
                                                    updateSelectValue(form.id,'unit', selectedOption.value);
                                                }}
                                                isDisabled={selectedRootBot.length === 0 }
                                                styles={{
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        backgroundColor: '#e9ecef',
                                                        color:'rgb(32, 34, 37)', 
                                                    }),
                                                    option: (baseStyles) => ({
                                                    ...baseStyles,
                                                    cursor:'pointer'
                                                    }),
                                                }}/>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Label>Где файл:</Form.Label>
                                                <Select 
                                                name='location'
                                                options={locationOptions}   
                                                value={form.location ? locationOptions.find(option => option.value === form.location ): locationOptions[0]}
                                                onChange={(selectedOption) => {
                                                    updateSelectValue(form.id, 'location', selectedOption.value);
                                                }}
                                                isDisabled={form.type === 'message' ? true : false}
                                                styles={{
                                                    menu: (baseStyles) => ({
                                                        ...baseStyles,
                                                        backgroundColor: '#e9ecef',
                                                        color:'rgb(32, 34, 37)', 
                                                      }),
                                                    option: (baseStyles) => ({
                                                    ...baseStyles,
                                                    cursor:'pointer'
                                                    }),
                                                  }}
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <Form.Label>Файл:</Form.Label>
                                                <Form.Control 
                                                className='form-control'
                                                type="text"
                                                placeholder="Имя файла"
                                                name="file"
                                                value={form.file}
                                                onChange={(e) => updateFormText(form.id, 'file', e.target.value)}
                                                disabled={form.type === 'message' ? true : false}
                                                
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='my-3'>
                                            
                                        </Row>
                                        <Row className='my-3'>
                                            <Col md={2}>
                                            </Col>
                                            <Col md={8}>
                                            <Form.Label>Варианты сообщений:</Form.Label>
                                            <Form.Control
                                            
                                            style={{resize:'none'}}
                                            as="textarea" 
                                            rows={6} 
                                            placeholder="Ввод каждого варианта с новой строки"
                                            name="text"
                                            value={form.text}
                                            onChange={(e) => updateFormText(form.id, 'text', e.target.value)}
                                            disabled={form.type === 'file_image' ||
                                            form.type === 'file_audio'||
                                            form.type === 'file_video' ? true : false}
                                            />
                                            </Col>
                                            <Col md={2}>
                                        
                                            </Col>
                                        </Row>  
                                        <Row className='mt-5'>
                                            <Col md={1}><Button type='button' className='success' onClick={() => addForm(index)}>+</Button></Col>
                                            <Col md={11}></Col>
                                        </Row>
                                    </Col>
                                    <Col md={1}><Button type='button' variant='danger'  onClick={() => deleteForm(form.id)}>x</Button></Col>
                                </Row>
                            </div>
                        ))}
                        {forms.length === 0 ? (
                             <Row className='mt-5'>
                             <Col md={1}></Col>
                             <Col md={1}><Button type='button' className='success' onClick={() => addForm(-1)}>+</Button></Col>
                             <Col md={10}></Col>
                         </Row>
                        ): null}
                        <Row className='my-5'>
                            <Col md={1}></Col>
                            <Col md={4}></Col>
                            <Col md={3}>
                                <Button type='button' className='primary' onClick={gatherFormData}>Сохранить Диалог</Button>
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                </Container>

            </Form>
        </div> 
    )}
    </>
    );
}
 
export default CreateDialogPage;





