import { initializeApp } from "firebase/app";

import { getStorage} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyCqbHFznNQdGMlTJ2WYMjp8EiNzIbkPCWI",
  authDomain: "discordbots-24313.firebaseapp.com",
  projectId: "discordbots-24313",
  storageBucket: "discordbots-24313.appspot.com",
  messagingSenderId: "5580403438",
  appId: "1:5580403438:web:0200ea34ed52d9d093f9ff"
};

const app = initializeApp(firebaseConfig);

// Получите ссылку на Firebase Storage
export const storage = getStorage(app);