import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Button,Image } from 'react-bootstrap';
import Select from 'react-select'
import './ChangeBotsPage.css';
import adapter from '../../../adapter';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../../firebaseApi';
import FuzzySet from 'fuzzyset';
import Loader from "../loader/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshAccessToken from '../../refreshAccessToken';

const ChangeBotsPage = ({setRefreshBots}) => {

  const userData = JSON.parse(localStorage.getItem('userData'));
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const botSexOptions = [
    { value: 'man', label: 'Мужской' },
    { value: 'woman', label: 'Женский' },
  ];


  const initialFormData = {
    userId:userData.id,
    username: '',
    name : '',
    sex: '',
    file: null,
    label: '',
    about: '',
    imageSource: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const [botSexValue, setBotSexValue] = useState(null);

  const [sameLabels, setSameLabels] = useState('');

 
  const handleInputChange = (name, value) => {

    if (name === 'sex') {
      setBotSexValue(value);
      setFormData({
        ...formData,
        [name]: value.value,
      });
    }else if(name === 'label'){
      const fuzzy = FuzzySet(botLabels);
      const results = fuzzy.get(value);
      if (results && results[0] && results[0][0] > 0.8) {
        setSameLabels(results[0][1]);
        setTimeout(() => {
          setSameLabels('');
        }, 1500);
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
    else{
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  
  };

  const handleFileInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleUpload(selectedFile, e.target);
    }
  };

  const [uploading, setUploading] = useState(false);
  const handleUpload = (file, inputElement) => {
    if (file) {
      const storageRef = ref(storage, `images/${file.name}`);
      setUploading(true);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            setFormData({
              ...formData,
              imageSource: downloadURL,
            });
            setUploading(false);

            if (inputElement) {
              inputElement.value = '';
            }
          });
        })
        .catch((error) => {
          console.error('Ошибка при загрузке файла', error);
          setUploading(false);
        });
    }
  };

  const handleLinkInputChange = (e) => {
    const link = e.target.value;
    setFormData((prevData) => ({ ...prevData, imageSource: link }));
  };

  const [loaderText, setLoaderText] = useState('');
  
  const handleAddBotProfile = async () => {
    if (isFormValid()) {
      setLoaderText('Меняем профиль бота ');
      try {
        const response = await adapter.post('/add_unit',formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });
        if (response.data.result === 'success') {
          toast.success('Профиль успешно изменён!');
          setFormData(initialFormData);
          setRefreshBots(true);
          setBotSexValue(null);
          setTimeout(() => {
            setLoaderText('');
          }, 1500);
        } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
          const refreshedToken = await refreshAccessToken(accessToken);
          if (refreshedToken === 'logout') {
            navigate('/', { replace: true });
          } else {
            const response =  adapter.post('/add_unit',formData, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              }
            });
            if (response.data.result === 'success') {
              toast.success('Профиль успешно изменён!');
              setFormData(initialFormData);
              setRefreshBots(true);
              setBotSexValue(null);
              setTimeout(() => {
                setLoaderText('');
              }, 1500);
            } else {
              console.log(response.data.message);
              toast.error('Произошла ошибка  ' + response.data.message);
              setLoaderText('');
            }
          }
        } else {
          console.log(response.data.message);
          toast.error('Произошла ошибка  ' + response.data.message);
          setLoaderText('');
        }
      } catch (error) {
        toast.error('Произошла ошибка  ' + error);
        console.error(error);
        setLoaderText('');
      }
    } else if (sameLabels !== '') {
      toast.error('Такая метка бота из сценария уже существует!');
      setLoaderText('');
    } else {
      toast.error('Не все поля заполнены. Пожалуйста, заполните все поля.');
      setLoaderText('');
    }
  };

  const isFormValid = () => {
    return (
      formData.username.trim() !== '' &&
      formData.name.trim() !== '' &&
      formData.sex.trim() !== '' &&
      formData.label.trim() !== '' &&
      formData.about.trim() !== ''
    );
  };

  const [botLabels, setBotLabels] = useState([]);

  const handleGetUnits = async () => {
    try {
      const response = await adapter.get('/get_units', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { userId: userData.id }
      });
      if (response.data.result === 'success') {
        console.log(response.data.array);
        const botLabels = response.data.array.map(bot => (bot.label
          ));
          setBotLabels(botLabels);
      }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
        const refreshedToken = await refreshAccessToken(accessToken);
        if (refreshedToken === 'logout') {
          navigate('/', { replace: true });
        }else {
          const response = await adapter.get('/get_units', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: { userId: userData.id }
          });
          if (response.data.result === 'success') {
            const botLabels = response.data.array.map(bot => (bot.label
                      ));
                      setBotLabels(botLabels);
          } else {
            console.log(response.data.message);
          }
        }
      }else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  };
  useEffect(() => {
    handleGetUnits();
  }, []);

  return (
    <>
    {loaderText !== ''? (
      <Loader loaderText={loaderText}/>
    ):(
    <div className="dialog-page-wrapper">
      <Form className="create-dialog-form p-5">
        <Container>
          <Row className='my-3'>
            <Col md={6}>
              <Form.Label>Username аккаунта (бота) из discord:</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Введите Username"
                autoComplete="off"
                value={formData.username}
                onChange={(e) => handleInputChange('username', e.target.value)}
              />
            </Col>
            <Col md={6}>
              <Form.Label>Globalname аккаунта (бота) из discord:</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Введите Globalname"
                autoComplete="off"
                value={formData.name}
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col md={6}>
              <Form.Label>Вставьте ссылку на файл :</Form.Label>
              <Form.Control
                type="text"
                placeholder='https://'
                onChange={handleLinkInputChange}
                value={formData.imageSource}
                autoComplete="off"
              />
              <Form.Label>Или загрузите своё фото:</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFileInputChange}
              />
            </Col>
            <Col md={6}>
              {formData.imageSource && (
                <div className="bot-photo-prew">
                  <p>Фото профиля:</p>
                  <Image
                    src={formData.imageSource}
                    alt="Preview"
                    width="100"
                    height="100"
                  />
                </div>
              )}
            </Col>
          </Row>
          <Row className='my-3'>
            <Col md={4}>
                <Form.Label>Пол Бота:</Form.Label>
                <Select 
                    name='sex' 
                    options={botSexOptions} 
                    value={botSexValue}
                    placeholder='Выбрать пол'
                    onChange={(e) => handleInputChange('sex', e)}
                    styles={{
                        menu: (baseStyles) => ({
                            ...baseStyles,
                            backgroundColor: '#e9ecef',
                            color: 'rgb(32, 34, 37)', 
                        }),
                        option: (baseStyles) => ({
                            ...baseStyles,
                            cursor: 'pointer'
                        }),
                    }}
                />
            </Col>
            <Col md={4}>
                <Form.Label>Метка бота из сценария (диалога):</Form.Label>
                <Form.Control
                type="text"
                name="label"
                placeholder="Введите label"
                value={formData.label}
                onChange={(e) => handleInputChange('label', e.target.value)}
                autoComplete="off"
                />
            </Col>
          
            <Col md={4}></Col>
          </Row>
          <Row>
            <Col md={4}></Col>
            <Col md={4}>{sameLabels !== '' ? (<p>Бот с меткой {sameLabels} уже сужествует!</p>): null}</Col>
            <Col md={4}></Col>
          </Row>
          <Row className='my-3'>
            <Col md={4}>
                <Form.Label>Bio аккаунта (бота) из discord:</Form.Label>
                <Form.Control
                                
                style={{resize:'none'}}
                as="textarea" 
                rows={3} 
                placeholder="Ищу спонсора для серьёзных отношений ❤️💋"
                name="about"
                value={formData.about}
                onChange={(e) => handleInputChange('about', e.target.value)}
                />
            </Col>
            <Col md={8}></Col>
          </Row>
          <Row className="mt-5">
            <Col md={4}>
              <Button
                variant="success"
                type="button"
                onClick={handleAddBotProfile}
              >
                Добавить профиль бота
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
    )}
    </>
  );
};
 
export default ChangeBotsPage;





