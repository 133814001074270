import adapter from "../adapter";
const userData = JSON.parse(localStorage.getItem('userData'));

async function refreshAccessToken() {
  
    const currUser = userData.id;
    try {
      const response = await adapter.post('/refresh_token', { currUser });
      if (response.data.result === 'success') {
        localStorage.setItem('accessToken', response.data.token);
        return response.data.token;
      } else {
        localStorage.setItem('authenticated', false);
        return 'logout';
      }
    } catch (error) {
      console.log(error);
    }
}

export default refreshAccessToken;