import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './components/loginPage/LoginPage';
import DashboardPage from './components/dashboardPage/DashboardPage';
import WhatsAppPage from './components/whatsAppPage/WhatsAppPage';
import ErrorPage from './components/errorPage/ErrorPage';
import CreateDialogPage from './components/createDialogPage/CreateDialogPage';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {


  const isAuth= JSON.parse(localStorage.getItem('authenticated'));
  const isUser = JSON.parse(localStorage.getItem('userData'));


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/404" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="/" 
          element={
            isAuth && isUser ? (
              <Navigate to="/dashboard" replace />
            ) :(
              <LoginPage />
            )
          } 
        />
       
        <Route
          path="/dashboard"
          element={
            isAuth && isUser ? (
              <DashboardPage />
            ) : (
              <Navigate to="/" replace state={{ from: '/dashboard' }} />
            )
          }
        />
        <Route
          path="/create_dialog"
          element={
            isAuth  &&  isUser ? (
              <CreateDialogPage />
            ) : (
              <Navigate to="/" replace state={{ from: '/create_dialog' }} />
            )
          }
        />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;