import { SyncLoader, HashLoader } from 'react-spinners';
import './Loader.css';


const Loader = ({loaderText}) => {
  return (
    <div className={`loader ${loaderText === 'Идёт генерация диалога, пожалуйста подождите ' && 'loader-sidebar'}`}>
      <div>
        { loaderText === 'Идёт генерация диалога, пожалуйста подождите ' ? (
          <>
            <h6 className='loader-text'>{loaderText}</h6>
            <HashLoader color="#5865f2" />
          </>
        ) : (
          <>
            <h3 className='loader-text'>{loaderText}</h3>
            <SyncLoader color="#5865f2" />
          </>
        )}
      </div>
    </div>
  );
};

export default Loader ;