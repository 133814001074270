import { useNavigate } from 'react-router-dom';
import {Col, Row, Image} from 'react-bootstrap';
import logo from '../../assets/img/Vector.png';
import './ErrorPage.css';


const ErrorPage = () => {

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate('/');

  };
  const headerStyle = {
    backgroundColor: '#202225',
    padding: '30px'
  };

  const headerLogo = {
      maxWidth: '100%',
      width: '50px',
      objectFit: 'cover',
  };
  const escBtn = {
    padding:'5px 25px',
    background:'#5865F2',
    color:'#fff',
    border:'none',
    borderRadius: '3px'
    
  };
   
  
    return (
      <div>
          <Row className='justify-content-center align-items-center text-light'   style={headerStyle}>
              <Col md={1}>
                  <Image src={logo} style={headerLogo} />
              </Col>
              <Col md={9}><h5 className="m-0">Discord - Home</h5></Col>
              <Col md={2} className="d-flex justify-content-end">
                <button style={escBtn} onClick={handleLogout}>Выход</button>
              </Col>
          </Row>
          <h1>404!</h1>
      </div>
    );
  };
  
  export default ErrorPage;