import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form,Button,Image } from 'react-bootstrap';
import './AddBotsPage.css';
import adapter from '../../../adapter';
import Loader from "../loader/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import refreshAccessToken from '../../refreshAccessToken';
const AddBotsPage = ({setRefreshBots}) => {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const accessToken = localStorage.getItem('accessToken');
  const initialData = {
    bot_id: '',
    email: '',
    password: '',
    registration_date: '',
    token: '',
    proxy_ip: '',
    proxy_port: '',
    proxy_user: '',
    proxy_password: '',
    description: ''
  };
  const navigate = useNavigate();
  const [data, setData] = useState(initialData);
 
  const handleInputChange = (name, value) => {

    if (name === 'proxy_ip') {
      const sanitizedValue = value.replace(/[^0-9.]/g, '');
      const limitedValue = sanitizedValue.slice(0, 15);
      const groups = limitedValue.split('.');

      const formattedGroups = groups.map((group, index) => {
        const sanitizedGroup = group.replace(/\D/g, '').slice(0, 3);
        return sanitizedGroup;
      });

      setData({
        ...data,
        [name]: formattedGroups.join('.'),
      });
    }else if(name === 'bot_id'){
      const sanitizedValue = value.replace(/\D/g, '');

      setData({
        ...data,
        [name]: sanitizedValue,
      });
    }else if(name === 'proxy_port'){
      const sanitizedValue = value.replace(/\D/g, '');
      const limitedValue = sanitizedValue.slice(0, 5);

      setData({
        ...data,
        [name]: limitedValue,
      });
    }else {
      setData({
        ...data,
        [name]: value,
      });
    }
    
  };


  const [loaderText, setLoaderText] = useState('');

  const handleAddBot = async ()  => {
    if (isFormValid()) {
      const botData = { ...data, userId: userData.id }; 
      setLoaderText('Добавляем бота ');

      try {
        const response = await adapter.post('/add_bot', botData,  {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        });
        if (response.data.result === 'success') {
          toast.success('Бот добавлен!')
          setData(initialData);
          setRefreshBots(true);
          setTimeout(() => {
            setLoaderText('');
          }, 1500);
        } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
          const refreshedToken =  refreshAccessToken(accessToken);
          if (refreshedToken === 'logout') {
            navigate('/', { replace: true });
          } else {
            const response = await adapter.post('/add_bot', botData,  {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              }
            });
            if (response.data.result === 'success') {
              toast.success('Бот добавлен!')
              setData(initialData);
              setRefreshBots(true);
              setTimeout(() => {
                setLoaderText('');
              }, 1500);
            } else {
              console.log(response.data.message);
              toast.error('Произошла ошибка  ' + response.data.message);
              setLoaderText('');
            }
          }
        } else {
          console.log(response.data.message);
          toast.error('Произошла ошибка  ' + response.data.message);
          setLoaderText('');
        }
      } catch (error) {
        toast.error('Произошла ошибка  ' + error);
        console.error(error);
        setLoaderText('');
      }

      
    } else {
      toast.error('Не все поля заполнены. Пожалуйста, заполните все поля.');
      setLoaderText('');
    }
  };

  const isFormValid = () => {

    return (
      data.bot_id.trim() !== '' &&
      data.email.trim() !== '' &&
      data.password.trim() !== '' &&
      data.registration_date.trim() !== '' &&
      data.token.trim() !== '' &&
      data.proxy_ip.trim() !== '' &&
      data.proxy_port.trim() !== '' &&
      data.proxy_user.trim() !== '' &&
      data.proxy_password.trim() !== '' 
    );
  };
  return (
    <>
    {loaderText !== ''? (
      <Loader loaderText={loaderText}/>
    ):(
    <div className='dialog-page-wrapper'>
      <Form className='create-dialog-form px-5 pt-3 pb-5'>
        <Container>
          <h5 className='add-bots-title'>Параметры бота:</h5>
          <Row className='my-3'>
            <Col md={4}>
              <Form.Label>Id аккаунта (бота) из discord</Form.Label>
              <Form.Control
                type='text'
                name='bot_id'
                placeholder='1110620013385220216'
                value={data.bot_id}
                autoComplete='off'
                onChange={(e) => handleInputChange('bot_id', e.target.value)}
              />
            </Col>
            <Col md={4}>
              <Form.Label>Email  (бота) из discord:</Form.Label>
              <Form.Control
                type='email'
                name='email'
                placeholder='igor111233@gmail.com'
                autoComplete='off'
                value={data.email}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </Col>
            <Col md={4}>
              <Form.Label>Пароль  (бота) из discord:</Form.Label>
              <Form.Control
                type='password'
                name='password'
                placeholder='Введите пароль'
                autoComplete='off'
                value={data.password}
                onChange={(e) => handleInputChange('password', e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col md={4}>
              <Form.Label>Дата регистрации (бота) в discord:</Form.Label>
              <Form.Control
                type='date'
                name='registration_date'
                value={data.registration_date}
                onChange={(e) => handleInputChange('registration_date', e.target.value)}
              />
            </Col>
            <Col md={5}>
              <Form.Label>Token  (бота) из discord:</Form.Label>
              <Form.Control
                type='text'
                name='token'
                placeholder='MTEwMjQxOTYxNDk3OTMyNTk2Mg.Gi7CZ8.3Pv48_1Kwybm2_NI5eWM8tvleNfR34A_BvA-11'
                value={data.token}
                onChange={(e) => handleInputChange('token', e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-3'>
            <Col md={6}>
              <Form.Label>Коментарий к боту (необязательный параметр):</Form.Label>
              <Form.Control
                style={{ resize: 'none' }}
                as='textarea'
                rows={3}
                placeholder="Опишите бота"
                name='text'
                value={data.description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                autoComplete="off"
              />
            </Col>
          </Row>
          <h5 className='add-bots-title'>Параметры прокси:</h5>
          <Row className='my-3'>
            <Col md={3}>
              <Form.Label>IP прокси:</Form.Label>
              <Form.Control
                type='text'
                name='proxy_ip'
                placeholder='45.147.246.207'
                value={data.proxy_ip}
                onChange={(e) => handleInputChange('proxy_ip', e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Form.Label>Порт прокси:</Form.Label>
              <Form.Control
                type='text'
                name='proxy_port'
                placeholder='62750'
                value={data.proxy_port}
                onChange={(e) => handleInputChange('proxy_port', e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Form.Label>Логин к прокси:</Form.Label>
              <Form.Control
                type='text'
                name='proxy_user'
                placeholder='Введите логин к прокси'
                value={data.proxy_user}
                onChange={(e) => handleInputChange('proxy_user', e.target.value)}
              />
            </Col>
            <Col md={3}>
              <Form.Label>Пароль к прокси:</Form.Label>
              <Form.Control
                type='password'
                name='proxy_password'
                placeholder='Введите пароль к прокси'
                value={data.proxy_password}
                onChange={(e) => handleInputChange('proxy_password', e.target.value)}
              />
            </Col>
          </Row>
          <Row className='my-5'>
            <Col md={3}>
              <Button className='py-2 mt-5'  variant='success' type='button' onClick={handleAddBot}>
                Создать бота
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </div>
    )}
    </>
  );

}
 
export default AddBotsPage;




