import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Button } from 'react-bootstrap';
import Select from 'react-select'
import './AiDialogGeneratePage.css';
import adapter from '../../../adapter';
import Loader from "../loader/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import nonameBot from '../../assets/img/633cb260a8e17.png'

const AiDialogGeneratePage = ({dialogJson}) => {

    return ( 
        <>
        {!dialogJson ? (
         
            <div className='dialog-page-wrapper py-5'>
                <em>Опишите каким вы видите свой диалог максимально ёмко и наш ИИ попробует воссоздать его.</em>
            </div> 
          ):(
        <div className='dialog-page-wrapper'>
            <Form className='create-dialog-form pb-5 px-2'>

                <Container className='message-list'>
                {Object.keys(dialogJson).map((message, index) => (
                    <Row key={index} className='message my-4 py-3'>
                        <Col md={1} className="me-3">
                            <img
                                src={nonameBot}
                                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                            />
                        </Col>
                        <Col md={10}>
                            <div className="d-flex align-items-center">
                                <div className="fw-bold">Отправитель: {dialogJson[message].unit} </div>
                            </div>
                            <div className="message-text">{dialogJson[message].text}</div>
                        </Col>
                   </Row>
                ))}
                </Container>

            </Form>
        </div> 
        )}
        </>
    );
}
 
export default AiDialogGeneratePage;





