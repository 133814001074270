import { useState } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from "axios";
// import MockAdapter from "axios-mock-adapter";
import './ResponsePanelGPT.css';
import adapter from "../../../../../adapter";

const ResponsePanelGPT = ({ replyId, gptAnswer, getAnswer }) => {
  
  

  const [buttonsVisible, setButtonsVisible] = useState(true);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const [buttonState, setButtonState] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [buttonName, setButtonName] = useState(null);

  const [inputShow, setInputShow] = useState(false);
  
 const [responseHide, setResponseHide ] = useState(false);

  const handleDivClick = (e) => {
    setButtonsVisible(true);
    setButtonsDisabled(false);
  };
  const [replyList, setReplyList] = useState([]);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const accessToken = localStorage.getItem('accessToken');


  



  const handleButtonClick = (buttonName, answerId) => {
    if(buttonName === 'conf'){
      if (window.confirm(`Подтверждаете отправку?`)) {
        adapter.post('/confirm_question', {
          // confirmedQuestionData: reply,
        })
        .then((res) => {
          console.log(res);
          setResponseHide(true)
          setButtonsVisible(false);
          setInputShow(false);
          setButtonState(false);
        })
        .catch((error) => {
          console.error(error);
        });
       
      }
    }else if(buttonName === 'ask'){

      getAnswer(answerId);
     
    }
    
    
    else{
      setButtonName(buttonName);
      setButtonState(true);
      setInputShow(true);
  
      if (buttonState) {
        if (buttonName === 'fix') {
          if (inputValue == '') {
            alert('заполните поле'); 

          }
          else if(window.confirm(`Вы уверены, что хотите исправить этот ответ на:"${inputValue}"?`)) {
            setInputShow(false);
            setButtonState(false);
            setInputValue('');
            if (inputValue) {
              // reply.answer = inputValue;
              adapter.post('/rewrite_answer', {
                newAnswer: inputValue,
                // data:reply
              })
            }
          }
          
        } else if (buttonName === 'again') {
          if (inputValue == '') {
            alert('заполните поле'); 

          }else if (window.confirm(`Вы уверены, что хотите переспросить "${inputValue}"?`)) {
            adapter.post('/reask_question', {
              question: inputValue,
              // data:reply
            })
            .then((res) => {
              alert('Запрос выполнен успешно!');
              // reply.answer = res.data.randomAnswer;
              setButtonsVisible(false);
              setInputShow(false);
              setButtonState(false);
              setInputValue('');
            })
            .catch((error) => {
              console.error(error);
            });
           
          }
        
        }
      }
    }
     
    
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  return (
    <div>
      {/* {buttonsDisabled && ( */}
        <div className={`ms-5 my-2 `} hidden={responseHide}>
          <div className="response-from-gpt " onClick={handleDivClick}>
            {/* <div className="fw-bold">Ответ ChatGPT:</div>
            <div>{reply.answer}</div> */}
          </div>
        </div>
      {/* )} */}
      {buttonsVisible && (
        <>
        {replyId && !gptAnswer ? (
          <div>
            <Row className={`mt-4 fade ${buttonsVisible ? "show" : ""}`}>
              <Col md={4}> 
                <Button
                  variant="light"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('ask', replyId)}
                  // disabled={buttonState && buttonName !== 'fix'}
                >
                  Cгенерировать ответ
                </Button>
              </Col>
            </Row>
            {inputShow && (
              <Row
                className={`justify-content-between align-items-end mt-2 fade ${
                  inputShow ? "show" : ""
                }`}
              >
                <Form.Group controlId="formBasicText">
                  <Form.Label>Введите текст:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Введите текст"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>
            )}
        </div>
        ):(
          <div>
            <Row className={`mt-4 fade ${buttonsVisible ? "show" : ""}`}>
              <Col md={4}>
                <Button
                  variant="success"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('conf')}
                  disabled={buttonState && buttonName !== 'conf'}
                >
                  Принять
                </Button>
              </Col>
              <Button
                  variant="light"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('ask')}
                  // disabled={buttonState && buttonName !== 'fix'}
                >
                  Генерировать ответ
                </Button>
              <Col md={4}>
                <Button
                  variant="danger"
                  className="px-3 py-2 w-100"
                  onClick={() => handleButtonClick('fix')}
                  disabled={buttonState && buttonName !== 'fix'}
                >
                  Исправить
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="warning"
                  className="px-3 py-2 w-100"
                  style={{ color: "#36393f" }}
                  onClick={() => handleButtonClick('again')}
                  disabled={buttonState && buttonName !== 'again'}
                >
                  Переспросить
                </Button>
              </Col>
            </Row>
            {inputShow && (
              <Row
                className={`justify-content-between align-items-end mt-2 fade ${
                  inputShow ? "show" : ""
                }`}
              >
                <Form.Group controlId="formBasicText">
                  <Form.Label>Введите текст:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Введите текст"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>
            )}
          </div>
        )}
        
        </>
      )}
    </div>
  );
};
export default ResponsePanelGPT;