import React, { useState, useEffect } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './ViewDialogPage.css';
import adapter from '../../../adapter';
import userImage1 from '../../assets/img/user1.jpg';
import { toast } from 'react-toastify';
import refreshAccessToken from '../../refreshAccessToken';
const ViewDialogPage = ({sidebarSelectedElemId}) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const accessToken = localStorage.getItem('accessToken');
    const [dialog, setDialog] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
      const fetchData = async () => {
        if (sidebarSelectedElemId !== null) {
            try {
                const response = await adapter.get('/get_selected_dialog', {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                  params: {  dialogId: sidebarSelectedElemId,
                    userId:userData.id
                }
                });
                if (response.data.result === 'success') {
                    if (response.data === null) {
                        toast.error("Не найдено сохранённых диалогов");
                    } else {
                      const dialog = response.data.array;
                      setDialog(JSON.parse(dialog.text));
                    }
                } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
                  const refreshedToken = refreshAccessToken(accessToken);
                  if (refreshedToken === 'logout') {
                    navigate('/', { replace: true });
                  } else {
                    const response = await adapter.get('/get_selected_dialog', {
                        headers: {
                          Authorization: `Bearer ${accessToken}`,
                        },
                        params: {  dialogId: sidebarSelectedElemId,
                          userId:userData.id
                      }
                      });
                      if (response.data.result === 'success') {
                          if (response.data === null) {
                              toast.error("Не найдено сохранённых диалогов");
                          } else {
                            const dialog = response.data.array;
                            setDialog(JSON.parse(dialog.text));
                          }
                      } else {
                      console.log(response.data.message);
                    }
                  }
                }else if (response.data.result === 'error' && response.data.message === 'No dialog found'){
                    setDialog([]);
                } else {
                  console.log(response.data.message);
                }
              } catch (error) {
                console.log(error)
              }

          }
        }
        fetchData();
    }, [sidebarSelectedElemId]);

    return ( 
        <div className='dialog-page-wrapper'>
            <div className='create-dialog-form p-5'>

                <Container>
                    <div>
                    {dialog && dialog.length > 0 ? (
                        <>
                            {dialog.map((item, index) => (
                            item.text && item.text[0] ? (

                            <div
                            key={index}
                            id={`message-${item.position}`}
                            className='message'
                            >
                                <Row className='align-items-center my-5 px-4 py-3'>
                                    <Col md={1} className="me-3 mt-3">
                                        <img src={userImage1}  style={{ width: "50px", height: "50px", borderRadius: "50%" }}/>
                                    </Col>
                                    <Col md={10}>
                                        <small className='d-block mb-3'>Назначен отправитель: <strong>{item.unit}</strong></small>
                                        <div className="message-text">{item.text}</div>
                                    </Col>
                                </Row>
                            </div>
                            ) : null
                            ))}
                        </>
                    ) : (
                    <p>Выберите диалог из списка слева</p>
                    )}
                    </div>
                </Container>

            </div>
        </div> 
    );
}
 
export default ViewDialogPage;





