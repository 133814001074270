

import { useState } from 'react';
import React from 'react';
import { Button, Form } from 'react-bootstrap';

function WhatsAppPage() {

  const [inputValue, setInputValue] = useState('');
  const [buttonName, setButtonName] = useState(null);
  const [buttonState, setButtonState] = useState(null);

  const handleButtonClick = (buttonName) => {
   
      setButtonName(buttonName);
      setButtonState(true);
  
      // Если пользователь уже нажал на кнопку, то выполнить код в блоке else
      if (buttonState) {
        if (buttonName === 'fix') {
          console.log('fix ' + inputValue);
          setButtonState(false);
        } else if (buttonName === 'again') {
          console.log(' again ' + inputValue);
          setButtonState(false);
        }
      }
    
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <Button
            onClick={() => handleButtonClick('conf')}
            disabled={buttonState && buttonName !== 'conf'}
          >
            conf
          </Button>
          <Button
            onClick={() => handleButtonClick('fix')}
            disabled={buttonState && buttonName !== 'fix'}
          >
            fix
          </Button>
          <Button
            onClick={() => handleButtonClick('again')}
            disabled={buttonState && buttonName !== 'again'}
          >
            again
          </Button>
        </div>
        <div className="col-md-4">
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Введите данные:</Form.Label>
            <Form.Control type="text" value={inputValue} onChange={handleInputChange} />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}
  
  export default WhatsAppPage;