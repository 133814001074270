import React, { useState } from "react";
import { Row, Col,Image } from "react-bootstrap";
import logo from '../../../assets/img/discord-logo.png';
import createDialogIcon from '../../../assets/img/free-icon-talk-1170143.png';
import viewChatIcon from '../../../assets/img/chat.png';
import changeBotsIcon from '../../../assets/img/replacement.png';
import aiGeneratorIcon from '../../../assets/img/ai.png';
import addBotsIcon from '../../../assets/img/add-group.png';
import './DashboardMenu.css';
const DashboardMenu = ({handleSelectMenuItem}) => {

    
    const [selectedMenuItem, setSelectedMenuItem] = useState('discord');

    return (

        <Row className="flex-column">
            <Col onClick={()=> {
                handleSelectMenuItem('discord');
                setSelectedMenuItem('discord');
            }}>
                <div className={`menu-item__img-wrapper ${selectedMenuItem === 'discord' ? 'menu-item--select': ''}`} >
                    <Image className={`menu-item__img ${selectedMenuItem === 'discord' ? 'scaled': ''}`} src={logo} />
                </div>
            </Col>
            <Col onClick={()=> {
                handleSelectMenuItem('createChat'); 
                setSelectedMenuItem('createChat');
            }}>
            <div className={`menu-item__img-wrapper ${selectedMenuItem === 'createChat' ? 'menu-item--select': ''}`} >
                    <Image  className={`menu-item__img ${selectedMenuItem === 'createChat' ? 'scaled': ''}`} src={createDialogIcon}  />
                </div>
            </Col>
            <Col onClick={()=> {
                handleSelectMenuItem('viewChat'); 
                setSelectedMenuItem('viewChat');
            }}>
            <div className={`menu-item__img-wrapper ${selectedMenuItem === 'viewChat' ? 'menu-item--select': ''}`} >
                    <Image  className={`menu-item__img ${selectedMenuItem === 'viewChat' ? 'scaled': ''}`} src={viewChatIcon}  />
                </div>
            </Col>
            <Col onClick={()=> {
                handleSelectMenuItem('changeBots'); 
                setSelectedMenuItem('changeBots');
            }}>
            <div className={`menu-item__img-wrapper ${selectedMenuItem === 'changeBots' ? 'menu-item--select': ''}`} >
                <Image  className={`menu-item__img ${selectedMenuItem === 'changeBots' ? 'scaled': ''}`} src={changeBotsIcon}  />
            </div>
            </Col>
            <Col onClick={()=> {
                handleSelectMenuItem('addBots'); 
                setSelectedMenuItem('addBots');
            }}>
            <div className={`menu-item__img-wrapper ${selectedMenuItem === 'addBots' ? 'menu-item--select': ''}`} >
                <Image  className={`menu-item__img ${selectedMenuItem === 'addBots' ? 'scaled': ''}`} src={addBotsIcon}  />
            </div>
            </Col>
            <Col onClick={()=> {
                handleSelectMenuItem('dialogsGenerator'); 
                setSelectedMenuItem('dialogsGenerator');
            }}>
            <div className={`menu-item__img-wrapper ${selectedMenuItem === 'dialogsGenerator' ? 'menu-item--select': ''}`} >
                <Image  className={`menu-item__img ${selectedMenuItem === 'dialogsGenerator' ? 'scaled': ''}`} src={aiGeneratorIcon}  />
            </div>
            </Col>
            
           
        </Row>


    );
}

export default DashboardMenu;