import { useContext  } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const SidebarButtons = ({handleButtonClick, setNewMessages, setButtonPrew, setButtonNext,setButtonLast}) => {
  const handleClick = (buttonType) => {
    handleButtonClick(buttonType);
  };

    const sideBarButtonsStyle = {
        position:'absolute',
        bottom:'-40px',
        right:'0px',
        background:'rgb(54 57 63)',
        borderRadius:'7px 7px 0 0px',
        padding:''
    }
    const notificationStyle = {
      width:'20px',
      height:'20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent:'center',
      backgroundColor:'#ED4245',
      fontSize: '10px',
    }
    return (
      <div  style={sideBarButtonsStyle}>
        <Row>
          <Col className="m-2">
            <Button
              className="mx-auto p-2 w-100 btn-sm"
              variant="success"
              onClick={() => handleClick("previous")}
              disabled={setButtonPrew ? true : false}
            >
              Предыдущий вопрос
            </Button>
          </Col>
  
          <Col className="m-2">
            <Button
              className="mx-auto p-2 w-100 btn-sm"
              variant="success"
              onClick={() => handleClick("next")}
              disabled={setButtonNext ? true : false}
            >
              Следующий вопрос
            </Button>
          </Col>
          <Col className="m-2">
            <Button
              className="mx-auto p-2 w-100 btn-sm"
              variant="outline-info"
              onClick={() => handleClick("last")}
              disabled={setButtonLast ? true : false}
            >
              Последнее сообщение
            </Button>
          </Col>
          <Col className="m-2">
            <Button
              className="mx-auto p-2 w-100 d-flex justify-content-around align-items-center btn-sm"
              variant="light"
              onClick={() => handleClick("new")}
              disabled={!setNewMessages || setNewMessages.count === 0 || setNewMessages.count === undefined}
            >
              Новые сообщения
              {setNewMessages && setNewMessages.count !== 0 && setNewMessages.count !== undefined && (
                <small className="badge badge-pill rounded-circle" style={notificationStyle}>
                  {setNewMessages.count}
                </small>
              )}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

export default SidebarButtons;