import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Nav, Collapse  } from 'react-bootstrap';
import './Sidebar.css';
import adapter from '../../../../adapter';
import copy from 'clipboard-copy';
import { Row, Col, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "../../loader/Loader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import refreshAccessToken from "../../../refreshAccessToken";


const Sidebar = ({ selectedSidebarElem, activePage, refreshSidebarData, generatedDialog}) => {

  const dilaogLangOptions = [
    { value: 'RU', label: 'Русский' },
    { value: 'EN', label: 'Английский' },
  ];

  const userData = JSON.parse(localStorage.getItem('userData'));
  const accessToken = localStorage.getItem('accessToken');

  const sidebarLinksHeader ={

    marginLeft:'15px',
    marginBottom:'0',
    padding:'10px',
    fontWeight:'bold',
    fontSize:'14px',
    borderBottom:'1px solid #42454A',
    color:'#FFFFFF',
    opacity:'0.5',
    cursor:'pointer'

  };
  const sidebarLinksBotHeader ={

    marginLeft:'15px',
    marginBottom:'0',
    padding:'10px',
    fontWeight:'bold',
    fontSize:'14px',
    borderBottom:'1px solid #42454A',
    color:'#FFFFFF',
    opacity:'0.5',
    cursor:'pointer'

  };
  const sidebarLinks = {
    marginLeft: '15px',
    padding: '10px',
    backgroundColor: '#393C43',
    color: '#FFFFFF',
    borderRadius: '0 0 5px 5px',
    cursor: 'pointer',
    listStyleType: 'none',
    fontSize: '14px',
    
  };
  const notificationStyle = {
    width:'20px',
    height:'20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    backgroundColor:'#ED4245',
    fontSize: '10px',
  }
  const navigate = useNavigate();

  const [currGroup, setCurrGroup] = useState(null);
 
 

  const [chatsData, setChatsData] = useState([]);
  const [sidebarData, setSidebarData] = useState([]);

  const [isOpen, setIsOpen] = useState({});
  const [currentIdGroup, setCurrentIdGroup] = useState(null);



  const handleSidebarElemClick = (type, id)=>{
      selectedSidebarElem({
        type:type,
        id:id
      });
      setCurrGroup(id);
  }


  const getDialogsList = async () => {
    try {
      const response = await adapter.get('/get_dialogs_list', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { userId: userData.id }
      });
      if (response.data.result === 'success') {
        setSidebarData(response.data.array);
      } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
        const refreshedToken = await refreshAccessToken(accessToken);
        if (refreshedToken === 'logout') {
          navigate('/', { replace: true });
        } else {
          const response = await adapter.get('/get_dialogs_list', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: { userId: userData.id }
          });
          if (response.data.result === 'success') {
            setSidebarData(response.data.array);
          } else {
            console.log(response.data.message);
          }
        }
      }else if (response.data.result === 'error' && response.data.message === 'No dialogs found'){
        setSidebarData([]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  
  const [messagesAmounth, setMessagesAmounth] = useState(null);
  const [dialogPrompts, setDialogPrompts] = useState("");
  const [dialogLang, setDialogLang] = useState([]);
  const handlePromptInput = (e) => {
    if (dialogLang.length === 0) {
      toast.error('Сначала выберите язык диалога!');
    } else {
      const inputText = e.target.value;
      if (/\d/.test(inputText)) {
        toast.error('Ввод цифр не допускается!');
      } else {
        const isValidInput =
          (dialogLang.value === 'RU' && /^[а-яА-ЯёЁ\s]*$/.test(inputText)) ||
          (dialogLang.value === 'EN' && /^[a-zA-Z\s]*$/.test(inputText));
    
        if (!isValidInput) {
          toast.error('Введенный текст не соответствует выбранному языку!');
        }else{
          setDialogPrompts(inputText);
        }
      }
    }
    
  }
  
  const handleLangChange = (e) => {
    setDialogLang(e);
    setDialogPrompts('');
    setDialogData(
      {
          ...dialogData,
          language:e.value
      }
    )
  }
  const handleMessAmounthInput = (e) => {
    setMessagesAmounth(e.target.value);
  }
 
  const [loaderText, setLoaderText] = useState('');
  const [showContent, setShowContent] = useState(false);
  
  const handleGenerateDialog = async () => {

    const isValidInput =
    (dialogLang.value === 'RU' && /^[а-яА-ЯёЁ\s]*$/.test(dialogPrompts)) ||
    (dialogLang.value === 'EN' && /^[a-zA-Z\s]*$/.test(dialogPrompts));

    if (dialogLang.length === 0) {
      toast.error('Вы не выбрали язык диалога!');
    }else if (!isValidInput) {
      toast.error('Введенный текст не соответствует выбранному языку!');
    }else if(!messagesAmounth ){
      toast.error('Задайте количество сообщений в диалоге!');
    }else{
        setLoaderText('Идёт генерация диалога, пожалуйста подождите ');
        try {
          const data = {
            password: 'QhjkhJKHDJlsd82JHKL',
            num_messages: messagesAmounth,
            text: dialogPrompts,
            lang:dialogLang.value.toLowerCase()
          };
          const response = await adapter.post('/newdialog/', data)
          .then(response => {
            toast.success('Диалог успешно сгенерирован!');
            setShowContent(true);
            setDialogPrompts(null);
            setMessagesAmounth(null);
            generatedDialog(response.data.dialog)
            setDialogData(
              {
                  ...dialogData,
                  text:JSON.stringify(response.data.dialog)
              }
            );
            setLoaderText('');
          })
          .catch(error => {
            toast.error(error);
            console.error(error);
            setLoaderText('');
          });
        } catch (error) {
          console.error(error);
          toast.error(error);
          setLoaderText('');
        }
    }
    
  };


  const getDiscordChats = async () => {
    try {
      const response = await adapter.get('/get_chats', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          userId: userData.id, 
        },
      });

      if (response.data.result === 'success') {
        setChatsData(response.data.array);
      } 
      else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
        const refreshedToken = await refreshAccessToken(accessToken);
        if (refreshedToken === 'logout') {
          navigate('/', { replace: true });
        } else {
          const response = await adapter.get('/get_chats', {
            headers: {
              Authorization: `Bearer ${refreshedToken}`,
            },
            params: { userId: userData.id }
          });
          if (response.data.result === 'success') {
            setChatsData(response.data.array);
          } else {
            console.log(response.data.message);
          }
        }
      }else if (response.data.result === 'error' && response.data.message === 'No chats found'){
        setChatsData([]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const [botLabels, setBotLabels] = useState([]);


  const handleGetBots = async () => {
    try {
      const response = await adapter.get('/get_bots', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        params: { userId: userData.id }
      });
      if (response.data.result === 'success') {
        setSidebarData(response.data.array);
        const botLabels = response.data.array.map(bot => ({
          value: bot.label,
          label: bot.username,
        }));
        setBotLabels(botLabels);
      } else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
        const refreshedToken = await refreshAccessToken(accessToken);
        if (refreshedToken === 'logout') {
          navigate('/', { replace: true });
        } else {
          const response = await adapter.get('/get_bots', {
            headers: {
              Authorization: `Bearer ${refreshedToken}`,
            },
            params: { userId: userData.id }
          });
          if (response.data.result === 'success') {
            setSidebarData(response.data.array);
            const botLabels = response.data.array.map(bot => ({
              value: bot.label,
              label: bot.username,
            }));
            setBotLabels(botLabels);
          } else {
            console.log(response.data.message);
          }
        }
      }else if (response.data.result === 'error' && response.data.message === 'No bots found'){
        setSidebarData([]);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleGetUnits = async () => {
    try {
      const response = await adapter.get('/get_units', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: { userId: userData.id }
      });
      if (response.data.result === 'success') {
        setSidebarData(response.data.array);
      }else if (response.data.result === 'error' && response.data.message === 'Token is expired') {
        const refreshedToken = await refreshAccessToken(accessToken);
        if (refreshedToken === 'logout') {
          navigate('/', { replace: true });
        }else {
          const response = await adapter.get('/get_units', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            params: { userId: userData.id }
          });
          if (response.data.result === 'success') {
            setSidebarData(response.data.array);
          } else {
            console.log(response.data.message);
          }
        }
      }else if (response.data.result === 'error' && response.data.message === 'No units found'){
        setSidebarData([]);
      }else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (activePage === 'discord') {
      getDiscordChats();
    }else if(activePage === 'viewChat'){
      getDialogsList();
    }else if(activePage === 'changeBots' ){
      handleGetUnits();
    }
    else if(activePage === 'addBots' || activePage === 'dialogsGenerator'){
      handleGetBots();
    }
  }, [activePage, refreshSidebarData]);

  const [copiedText, setCopiedText] = useState(false);

  const copyImagePath = (imageUrl) => {
    copy(imageUrl);
    toast.success('Ссылка на изображение скопирована!');
  };

  const [dialogData, setDialogData] = useState({
    name:'',
    description:'',
    text:'',
    language:'',
    root: '',
    type:'dialog_gpt'
  });
  

  const handleDialogNameChange = (e)=>{
    setDialogData(
        {
            ...dialogData,
            name:e.target.value
        }
    )
  }
  const handleDialogDescChange = (e)=>{
    setDialogData(
        {
            ...dialogData,
            description:e.target.value
        }
    )
  }
  const [rootBotLabel, setRootBotLabel] = useState([]);
  const [selectedRootBot, setSelectedRootBot] = useState([]);
  const handleBotLableChange = (e)=>{
    setSelectedRootBot(e);
    setRootBotLabel(e);
    setDialogData(
        {
            ...dialogData,
            root:e.value
        }
    )
  }

  const handleSaveDialog = () => {
    if (!dialogData.name) {
      toast.error('Заполните обязательное поле: "Название диалога"');
    }else {
      setLoaderText('Сохраняем диалог')
      const updatedDialogData = { ...dialogData, userId: userData.id };
      adapter.post('/create_dialog', updatedDialogData)
      .then(response => {
        setDialogData({
          name: '',
          description: '',
          text: [],
          language: '',
          root: null
        });
        resetInputs();
        toast.success('Диалог добавлен в базу!');
        setTimeout(() => {
            setLoaderText('');
        }, 1500);
        
      })
      .catch(error => {
        setLoaderText('');
        toast.error('Произошла ошибка, подробности в консоли');
        console.error(error);
        console.log(updatedDialogData);
      });
    }
  }
  const resetInputs = () => {
    setSelectedRootBot([]);
    setMessagesAmounth(null);
    setDialogPrompts('');
    setDialogLang([]);
    generatedDialog();
    setShowContent(false);
    setDialogData({
      name: '',
      description: '',
      text: [],
      language: '',
      root: null
    });
  }

  return (
    <div className="border-right " id="sidebar-wrapper" >
      <h6 className="sidebar-heading">
        { activePage === 'discord' || activePage === 'viewChat'
          ? 'Список всех диалогов:'
          : activePage === 'changeBots'
          ? 'Список всех профилей :'
          :activePage === 'dialogsGenerator' && !loaderText
          ? 'Введите параметры, что бы сгенерировать диалог с помощью ИИ:'
          :activePage === 'addBots'
          ? 'Список всех ботов:'
          : null}
      </h6>
      <Nav className="flex-column">
      {activePage === 'discord' ? (
        <>
          {chatsData.length > 0 ? (
            <>
              {chatsData.map(item => (
                <div key={item.channel_id}>
                  <p
                    style={sidebarLinksHeader}
                    className={`d-flex justify-content-between align-items-center my-2 ${currGroup === item.channel_id ? 'chat--selected': ''}`}
                    onClick={(event) => {
                      const newIsOpen = {}; 
                      newIsOpen[item.channel_id] = !isOpen[item.channel_id];
                    
                      chatsData.forEach((otherItem) => {
                        if (otherItem.channel_id !== item.channel_id) {
                          newIsOpen[otherItem.channel_id] = false;
                        }
                      });
                    
                      setIsOpen(newIsOpen); 
                      handleSidebarElemClick('chat', item.channel_id);
                    }}
                    aria-controls={`channel-${item.channel_id}-collapse`}
                    aria-expanded={isOpen[item.channel_id]}
                    data-channel-id={item.channel_id}
                  >
                    {item.channel_name} 
                    <span className="badge badge-pill rounded-circle" style={notificationStyle}>
                      {item.data.reduce((sum, sender) => sum + parseInt(sender.unique_users_count), 0)}
                    </span>
                  </p>
                  <Collapse in={isOpen[item.channel_id]} style={sidebarLinks}>
                    <ul id={`channel-${item.channel_id}-collapse`}>
                      {item.data.map(sender_data => (
                        <li className="list-channel-item d-flex justify-content-between align-items-center my-2" key={sender_data.sender_name}>
                          {sender_data.sender_name}
                          <span className="badge badge-pill rounded-circle" style={notificationStyle}>{sender_data.unique_users_count}</span>
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </div>
              ))}
            </>
          ) : (<em>Нет чатов для этого пользователя!</em>)}
        </>
      ): activePage === 'viewChat' ? (
        <>
        {sidebarData.length > 0 ? (
          <>
            {sidebarData.map((item =>(
              <div key={item.id}>
                <p
                  style={sidebarLinksHeader}
                  className={`d-flex justify-content-between align-items-center my-2 ${currGroup === item.id ? 'chat--selected': ''}`}
                  onClick={(event) => {
                    const newIsOpen = {}; 
                    newIsOpen[item.id] = !isOpen[item.id];
              
                  
                    chatsData.forEach((otherItem) => {
                      if (otherItem.id !== item.id) {
                        newIsOpen[otherItem.id] = false;
                      }
                    });
              
                    setIsOpen(newIsOpen); 
                    handleSidebarElemClick('dialog', item.id );
                  }}
                  aria-controls={`channel-${item.id}-collapse`}
                  aria-expanded={isOpen[item.id]}
                  data-channel-id={item.id}
                >
                  {item.name}<strong>{item.language}</strong>
                </p>
                <Collapse in={isOpen[item.id]} style={sidebarLinks}>
                  <ul id={`channel-${item.id}-collapse`}>
                  {item.description?( 
                    <li className="">
                      <strong>Описание:</strong>
                      <br></br>
                      <small><em>{item.description}</em></small>
                    </li>) 
                    :(
                      <li className="list-channel-item d-flex justify-content-between align-items-center my-2">
                        <small><em>Описание не добавлено</em></small>
                      </li>
                    )
                  }
                  </ul>
                </Collapse>
              </div>
            ))).reverse()
            } 
          </>
          ) : (<em>Нет диалогов для этого пользователя!</em>)}
        </>
      ): activePage === 'changeBots' ? (
      <>
        {sidebarData.length > 0 ? (
          <>
            {sidebarData.map((item =>(
              <div key={item.id}>
                <div
                  style={sidebarLinksBotHeader}
                  className={`d-flex justify-content-between align-items-start my-2 ${currGroup === item.id ? 'chat--selected': ''}`}
                  onClick={(event) => {
                    const newIsOpen = {}; 
                    newIsOpen[item.id] = !isOpen[item.id];
              
                  
                    chatsData.forEach((otherItem) => {
                      if (otherItem.id !== item.id) {
                        newIsOpen[otherItem.id] = false;
                      }
                    });
              
                    setIsOpen(newIsOpen); 
                    handleSidebarElemClick('changeBots', item.id );
                  }}
                  aria-controls={`channel-${item.id}-collapse`}
                  aria-expanded={isOpen[item.id]}
                  data-channel-id={item.id}
                >
                  <div  className="profile-img" onClick={(e) => { e.stopPropagation(); copyImagePath(item.images); }}>
                    <img src={item.images} ></img>
                  </div>
                <div>
                
                </div>
                  <div className="text-end">
                    {item.name}
                    <br></br>
                    <small>@{item.username}</small>
                  </div>
                
                </div>
                <Collapse in={isOpen[item.id]} style={sidebarLinks}>
                  <ul id={`channel-${item.id}-collapse`}>
                  {item.sex ? ( 
                    <li className="">
                      <strong>Пол:</strong>
                      <br></br>
                      <em>{item.sex}</em>
                    </li>) 
                    :(
                      <li className="list-channel-item d-flex justify-content-between align-items-center my-2">
                        <small><em>Не указан</em></small>
                      </li>
                    )
                  }
                  {item.label ? ( 
                    <li className="">
                      <strong>label бота:</strong>
                      <br></br>
                      <em>{item.label}</em>
                    </li>) 
                    :(
                      <li className="list-channel-item d-flex justify-content-between align-items-center my-2">
                        <small><em>Не указан</em></small>
                      </li>
                    )
                  }
                
                  {item.about ? ( 
                    <li className="">
                      <strong>Био:</strong>
                      <br></br>
                      <small><em>{item.about}</em></small>
                    </li>) 
                    :(
                      <li className="list-channel-item d-flex justify-content-between align-items-center my-2">
                        <small><em>Био не добавлено</em></small>
                      </li>
                    )
                  }
                  </ul>
                </Collapse>
              </div>
            ))).reverse()} 
        </>
        ) : (<em>Нет профилей ботов у этого пользователя!</em>)}
      </>
      ): activePage === 'addBots' ? (
      <>
        {sidebarData.length > 0 ? (
          <>
            {sidebarData.map((item =>(
          <div key={item.id}>
            <div
              style={sidebarLinksBotHeader}
              className={`d-flex justify-content-between align-items-start my-2 ${currGroup === item.id ? 'chat--selected': ''}`}
              onClick={(event) => {
                const newIsOpen = {}; 
                newIsOpen[item.id] = !isOpen[item.id];
          
              
                chatsData.forEach((otherItem) => {
                  if (otherItem.id !== item.id) {
                    newIsOpen[otherItem.id] = false;
                  }
                });
          
                setIsOpen(newIsOpen); 
                handleSidebarElemClick('changeBots', item.id );
              }}
              aria-controls={`channel-${item.id}-collapse`}
              aria-expanded={isOpen[item.id]}
              data-channel-id={item.id}
            >
              <div  className="profile-img" onClick={(e) => { e.stopPropagation(); copyImagePath(item.images); }}>
                <img src={item.images} ></img>
              </div>
             <div>
             
             </div>
              <div className="text-end">
                {item.name ? item.name : 'Без имени'}
                <br></br>
                <small>{item.username ? "@" + item.username  : 'Без username'}</small>
                <br></br>
                <small>{item.email ? item.email : 'Без email'}</small>
              </div>
             
            </div>
            <Collapse in={isOpen[item.id]} style={sidebarLinks}>
              <ul id={`channel-${item.id}-collapse`}>
              {item.sex ? ( 
                <li >
                  <strong>Пол:</strong>
                  <br></br>
                  <em>{item.sex}</em>
                </li>) 
                :(
                  <li>
                    <strong>Пол:</strong>
                    <br></br>
                    <small><em>Не указан</em></small>
                  </li>
                )
              }
              {item.label ? ( 
                <li >
                  <strong>label бота:</strong>
                  <br></br>
                  <em>{item.label}</em>
                </li>) 
                :(
                  <li>
                    <strong>label бота:</strong>
                    <br></br>
                    <small><em>Не указан</em></small>
                  </li>
                )
              }
             
              {item.about ? ( 
                <li>
                  <strong>Био:</strong>
                  <br></br>
                  <small><em>{item.about}</em></small>
                </li>) 
                :(
                  <li>
                    <strong>Био:</strong>
                    <br></br>
                    <small><em>Био не добавлено</em></small>
                  </li>
                )
              }
               {item.description ? ( 
                <li>
                  <strong>Описание бота:</strong>
                  <br></br>
                  <small><em>{item.description}</em></small>
                </li>) 
                :(
                  <li>
                    <strong>Описание бота:</strong>
                    <br></br>
                    <small><em>Описание не добавлено</em></small>
                  </li>
                )
              }
              {item.nospeak ? ( 
                <li>
                  <strong>Nospeak:</strong>
                  <br></br>
                  <small><em>true</em></small>
                </li>) 
                :(
                <li>
                  <strong>Nospeak:</strong>
                  <br></br>
                  <small><em>false</em></small>
                </li>
                )
              }
              </ul>
            </Collapse>
          </div>
        ))).reverse()} 
        </>
        ) : (<em>Нет  ботов у этого пользователя!</em>)}
      </>
      )
      : activePage === 'dialogsGenerator' ? (
        <>
        {loaderText !== ''? (
          <Loader loaderText={loaderText}/>
        ):(
        <Form className='p-3'>
          <Row className="my-2">
            <Form.Label>Язык диалога:</Form.Label>
            <Col md={6}>
              <Select 
                  name='lang' 
                  options={dilaogLangOptions} 
                  value={dialogLang}
                  onChange={handleLangChange}
                  placeholder='Выберите язык'
                  styles={{
                      menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: '#e9ecef',
                          color: 'rgb(32, 34, 37)', 
                      }),
                      option: (baseStyles) => ({
                          ...baseStyles,
                          cursor: 'pointer'
                      }),
                  }}
                  />
            </Col>
          </Row>
          <Row className="my-2">
            <Col md={12}>
              <Form.Label>Опишите ваш диалог:</Form.Label>
              <Form.Control
                name="text"
                placeholder={
                  dialogLang.value === 'EN'
                    ? 'Dialog for a crypto project'
                    : 'Диалог для криптопроекта'
                }
                autoComplete="off"
                value={dialogPrompts}
                onChange={handlePromptInput}
                as="textarea"
              />
            </Col>
          </Row>
          <Row className="my-2">
          <Form.Label>Введите количество сообщений:</Form.Label>
            <Col md={3}>
              <Form.Control
                type="number"
                name="amount"
                autoComplete="off"
                value={messagesAmounth}
                onChange={handleMessAmounthInput}
                min={1} 
                max={100} 
                style={{ resize: 'none' }}
              />
            </Col>
          </Row>
          {showContent ? (
            <>
              <Row className="my-2 mt-3">
                <Col md={6}>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleGenerateDialog}
                  >
                    Пересоздать
                  </Button>
                </Col>
              </Row>
              <Row className="my-2 mt-5">
                <Col md={12}>
                  <Form.Label>Название диалога:</Form.Label>
                  <Form.Control
                  type="text"
                  name="dialogName"
                  placeholder="Введите название"
                  value={dialogData.name}
                  onChange={handleDialogNameChange}
                  />
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={12}>
                  <Form.Label>Описание диалога <small>(необязательно)</small>:</Form.Label>
                  <Form.Control
                  type="text"
                  name="dialogDesc"
                  placeholder="Введите описание"
                  value={dialogData.description}
                  onChange={handleDialogDescChange}
                  />
                </Col>
              </Row>
              <Row className="my-2">
                <Col md={5}>
                  <Form.Label>Выбрать ROOT бота:</Form.Label>
                  <Select name='botLabel' options={botLabels} value={selectedRootBot} onChange={handleBotLableChange}
                    styles={{
                      menu: (baseStyles) => ({
                          ...baseStyles,
                          backgroundColor: '#e9ecef',
                          color:'rgb(32, 34, 37)', 
                        }),
                      option: (baseStyles) => ({
                      ...baseStyles,
                      cursor:'pointer'
                      }),
                    }}/>
                </Col>
              </Row>
              <Row className="my-2 mt-3">
                <Col md={6}>
                  <Button
                    variant="success"
                    type="button"
                    onClick={handleSaveDialog}
                  >
                    Сохранить
                  </Button>
                </Col>
              </Row>
            </>
          ):(
            <Row className="my-2 mt-4">
              <Col md={12}>
                <Button
                  variant="success"
                  type="button"
                  onClick={handleGenerateDialog}
                >
                  Сгенерировать
                </Button>
              </Col>
            </Row>
          )
          
          }
          
        </Form>
       )}
       </>
      )
      :null}
        
       
        
      </Nav>
      
    </div>
  );
};
export default Sidebar; 




